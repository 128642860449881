import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b08e3fb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout"
};
const _hoisted_2 = {
  class: "help-item-title"
};
const _hoisted_3 = {
  class: "help-item-message"
};
import { onMounted, ref } from "vue";
export default {
  __name: 'MoreQuestionComponents',
  setup(__props) {
    const activeName = ref('1');
    const questions = ref([{
      title: '活码有视频教程吗？',
      message: '有。点击首页的【活码】，然后点击右上角【使用教程】即可。'
    }, {
      title: '非会员可以使用吗？',
      message: '可以。本App永久免费，如果免费版不能满足需求，可购买会员。'
    }, {
      title: '会员购买建议',
      message: '短期使用，建议购买短期会员，长期使用的话，建议购买永久会员，更划算。'
    }, {
      title: '购买会员能否开发票？',
      message: '可以。点击首页底部【人工客服】，联系人工处理。'
    }]);
    onMounted(() => {
      document.title = '帮助';
    });
    return (_ctx, _cache) => {
      const _component_van_collapse_item = _resolveComponent("van-collapse-item");
      const _component_van_collapse = _resolveComponent("van-collapse");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_collapse, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => activeName.value = $event),
        accordion: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questions.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, [_createVNode(_component_van_collapse_item, {
            name: index
          }, {
            title: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1)]),
            default: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString(item.message), 1)]),
            _: 2
          }, 1032, ["name"])]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};