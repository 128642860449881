import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "body"
};
import DownloadComponents from "@/components/DownloadComponents.vue";
import MidComponents from "@/components/MidComponents.vue";
import { onMounted } from "vue";
export default {
  __name: 'MainComponents',
  setup(__props) {
    onMounted(() => {
      document.title = '二维码和条形码生成器';
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(DownloadComponents), _createVNode(MidComponents)]);
    };
  }
};