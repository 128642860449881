import {createApp} from 'vue'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'lib-flexible'
import App from "@/App.vue";
import {router} from "@/utils/RouterUtil";
import {
    Skeleton,
    PasswordInput,
    NumberKeyboard,
    Toast,
    Button,
    Dialog,
    Overlay,
    Icon,
    Collapse,
    CollapseItem,
    Cell,
    CellGroup,
    RadioGroup,
    Radio,
    Field,
    Divider,
    Popup,
} from 'vant';
import lazyPlugin from 'vue3-lazy'
import 'vant/lib/index.css';
import 'vant/es/toast/style';
import 'vant/es/image-preview/style';
import 'vant/es/dialog/style';

const app = createApp(App)

app.use(PasswordInput);
app.use(NumberKeyboard);
app.use(Skeleton);
app.use(Toast);
app.use(Button);
app.use(Dialog);
app.use(Overlay);
app.use(Icon);
app.use(Collapse);
app.use(CollapseItem);
app.use(Cell)
app.use(CellGroup)
app.use(Radio)
app.use(RadioGroup)
app.use(Field)
app.use(Divider)
app.use(Popup)
app.use(lazyPlugin, {
    loading: require('@/assets/loadingImg.png'), // 图片加载时默认图片
    error: require('@/assets/errImage.png') // 图片加载失败时默认图片
})
// app.use(ElementPlus)

app.use(router)

app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


// createApp(App).mount('#app')
