export default {
  name: 'App'
};
window.onload = function () {
  document.addEventListener('touchstart', function (e) {
    console.log("1", e.touches.length);
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  });
  document.addEventListener('gesturestart', function (e) {
    console.log("2");
    e.preventDefault();
  });
};